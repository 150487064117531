/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import HeroStats from "./HeroStats";
import { useQuery } from "@tanstack/react-query";
import { getPortfolioPowerQuery, getSitePowerCapacity } from "../../lib/Queries";
import axios from "axios";

const PortfolioStats = () => {
  const [portfolioStats, setPortfolioStats] = useState({});

  /**
   * We need to calculate the following for our "hero stats":
   *
   * Total power generation (solar, Genset)
   * Total Solar generation
   * Total unused genset capacity (nameplate - production)
   * Total BESS kWh (nameplate * SoC)
   *
   * To do this, we'll make a call to the site diagram API for each site in turn, then manually
   * aggregate the results.
   */

  const { isLoading, error, data } = useQuery({
    ...getPortfolioPowerQuery(),
    refetchInterval: 15000,
  });

  useEffect(() => {
    // when we have portfolio power data, start fetching the site power info per site
    if (data?.sites.length > 0) {
      let bessTotalEnergy = 0;
      let bessEnergyCapacity = 0;
      let gensetTotalProduction = 0;
      let gensetTotalCapacity = 0;
      let solarTotalProduction = 0;
      let totalCapacity = 0;
      data.sites.forEach(async (site) => {
        await getSitePowerCapacity(site.uuid)
          .then((data) => {
            data.assemblies.forEach((source) => {
              if (source.array) {
                source.array.forEach((subsource) => {
                  if (subsource.scaleos_type.includes("Solar")) {
                    if (subsource.power > 0) {
                      solarTotalProduction += subsource.power;
                    }
                    if (subsource.power_capacity) {
                      totalCapacity += subsource.power_capacity;
                    }
                  } else if (subsource.scaleos_type.includes("BESS")) {
                    // calculate BESS energy by SoC * nameplate capacity
                  } else if (subsource.scaleos_type.includes("Genset")) {
                    if (subsource.power > 0) {
                      gensetTotalProduction += subsource.power;
                    }
                    // add genset capacity to running total
                    if (subsource.power_capacity) {
                      gensetTotalCapacity += subsource.power_capacity;
                      totalCapacity += subsource.power_capacity;
                    }
                  }
                });
              }
              if (source.scaleos_type === "Solar") {
                if (source.power > 0) {
                  solarTotalProduction += source.power;
                }
                if (source.power_capacity) {
                  totalCapacity += source.power_capacity;
                }
              } else if (source.scaleos_type === "BESS") {
                // calculate BESS energy by SoC * nameplate capacity
                if (source.energy_capacity) {
                  let bessStored = source.energy_capacity * (source.soc / 100);
                  bessTotalEnergy += bessStored;
                  bessEnergyCapacity = bessEnergyCapacity += source.energy_capacity;
                }
              } else if (source.scaleos_type === "Genset") {
                if (source.power > 0) {
                  gensetTotalProduction += source.power;
                }
                // add genset capacity to running total
                if (source.power_capacity) {
                  gensetTotalCapacity += source.power_capacity;
                  totalCapacity += source.power_capacity;
                }
              }
            });
            setPortfolioStats({
              totalCapacity: parseFloat(totalCapacity / 1000).toFixed(2),
              bessTotalEnergy: parseFloat(bessTotalEnergy / 1000).toFixed(2),
              bessEnergyCapacity: parseFloat(bessEnergyCapacity / 1000).toFixed(2),
              gensetTotalProduction: parseFloat(gensetTotalProduction / 1000).toFixed(2),
              gensetAvailableCapacity: parseFloat(
                (gensetTotalCapacity - gensetTotalProduction) / 1000,
              ).toFixed(2),
              solarTotalProduction: parseFloat(solarTotalProduction / 1000).toFixed(2),
            });
          })
          .catch((err) => {});
      });
    }
  }, [data]);

  if (data) {
    return (
      <Box flexDirection="column" ml="80px" pt="10px" pl="4em" pr="4em" alignItems="center">
        <HeroStats portfolioStats={portfolioStats} />
      </Box>
    );
  }
};

export default PortfolioStats;
