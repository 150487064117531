/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2022 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
  Checkbox,
  Grid,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import {
  getOrganizationsListQuery,
  putUserSettingsMutation,
  deleteUserMutation,
  postUserSettingsMutation,
  getOrganizationSitesListQuery,
} from "../../lib/Queries";
import Loading from "../Shared/Loading";
import DataComponentError from "../Shared/DataComponentError";
import { LoadingButton } from "@mui/lab";
import PropTypes from "prop-types";
import DeleteUserModal from "./UserDeleteModal";
import { useTheme } from "@mui/material/styles";
import { useQuery } from "@tanstack/react-query";
import UserRoles from "../../lib/UserRoles";
import _ from "lodash";
import { Alert } from "@mui/material";

const styles = {
  settingsPanel: {
    width: "100%",
    py: "3rem",
    px: "4rem",
  },
  settingsNewPanel: {
    width: "100%",
    py: "3rem",
  },
  subtitle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "700",
  },
  controlList: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridAutoFlow: "column",
  },
};

const UserSettings = ({ user, onClose, onSave, onDelete }) => {
  const theme = useTheme();
  const [dirty, setDirty] = useState(false);
  const [name, setName] = useState(user?.name || null);
  const [email, setEmail] = useState(user?.email || null);
  const [organization, setOrganization] = useState(user?.customer || null);
  const [role, setRole] = useState(user?.role || null);
  const [open, setOpen] = useState(false); // State to control modal visibility
  const [selectedSites, setSelectedSites] = useState(new Set());
  const [allSelected, setAllSelected] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const allowedOrgUuid = "c300805b-a18a-489b-a6a2-bd1994d1dac4"; // UUID for Scale
  const isLoading = false;
  const error = false;
  const { data: organizationsList } = useQuery({
    ...getOrganizationsListQuery(),
  });
  const {
    data: siteList,
    isLoading: siteListIsLoading,
    refetch: refetchSiteList,
  } = useQuery({
    ...getOrganizationSitesListQuery(organization),
  });

  const mutation = useMutation({
    ...putUserSettingsMutation(),
    onSuccess: () => {
      refetchSiteList();
    },
  });

  const mutation_delete = useMutation({
    ...deleteUserMutation(),
  });

  const mutation_new_user = useMutation({
    ...postUserSettingsMutation(),
  });

  const handleError = (error) => {
    if (error.response?.status === 400 && error.response.data?.detail.includes("same")) {
      setErrorMessage("There is already a user with this email address.");
    } else if (error.response?.status === 500) {
      setErrorMessage(
        "User couldn’t be created. Try again in a moment. Contact the Software team if the issue continues.",
      );
    }
  };

  const saveSettings = () => {
    if (organization === allowedOrgUuid && !email.endsWith("@scalemicrogrids.com")) {
      setErrorMessage("To join the Scale organization, a user must have a Scale email address.");
      return;
    }
    const newSettings = {
      name: name,
      email_address: email,
      customer: organization,
      roles: [role],
      authorized_sites: user ? Array.from(selectedSites) : [],
    };

    if (user?.email) {
      mutation.mutate(newSettings, {
        onSuccess: () => {
          setDirty(false);
          onSave();
        },
      });
    } else {
      mutation_new_user.mutate(newSettings, {
        onSuccess: () => {
          setDirty(false);
          onClose();
        },
        onError: handleError,
      });
    }
  };

  const handleOrganizationChange = (newValue) => {
    if (newValue) {
      setOrganization(newValue.uuid);
      setSelectedSites(new Set());
      refetchSiteList();
      setDirty(true);
    }
  };

  const getRoles = () => {
    // Filter the roles based on the organization UUID
    const filteredRoles =
      organization === allowedOrgUuid ? ["operate:admin", "operate:user"] : ["portal:user"];

    return Object.entries(UserRoles)
      .filter(([key]) => filteredRoles.includes(key))
      .map(([key, value]) => (
        <MenuItem key={key} value={key}>
          {value}
        </MenuItem>
      ));
  };

  const allFieldsFilled = () => {
    return name && email && organization && role;
  };

  const handleDelete = () => {
    setOpen(true); // Open the confirmation modal
  };

  const confirmDelete = () => {
    mutation_delete.mutate(user?.email, {
      onSuccess: () => {
        console.log("User deleted successfully");
        setOpen(false); // Close the modal on success
        onDelete();
      },
      onError: (error) => {
        console.error("Error deleting user:", error);
        setOpen(false); // Close the modal on error
      },
    });
  };

  const handleSaveAndClose = () => {
    saveSettings();
  };

  const updateAllSelected = (selectedSites, siteList) => {
    if (!siteList || !siteList.sites) return;
    if (selectedSites.size === siteList.sites.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  };

  const getAllSiteUuids = () => {
    return siteList.sites.map((site) => site.uuid);
  };

  const handleToggleSelectAll = () => {
    if (allSelected) {
      setSelectedSites(new Set());
    } else {
      setSelectedSites(new Set(getAllSiteUuids()));
    }
    setAllSelected(!allSelected);
  };

  const selectedSitesChanged = () => {
    if (!user) return true;
    if (isLoading) return false;
    const currentSiteUuids = new Set(user.authorized_sites);
    return !_.isEqual(currentSiteUuids, selectedSites);
  };

  const hasUpdates = () => {
    return dirty || selectedSitesChanged();
  };

  const addSiteUuid = (uuid) => {
    setSelectedSites((prevItems) => new Set(prevItems).add(uuid));
  };

  const removeSiteUuid = (removeUuid) => {
    setSelectedSites((prevItems) => {
      const newSet = new Set(prevItems);
      newSet.delete(removeUuid);
      return newSet;
    });
  };

  const handleSiteSelectionChange = (uuid, event) => {
    const selected = event.target.checked;
    if (selected) {
      addSiteUuid(uuid);
    } else {
      removeSiteUuid(uuid);
    }
  };

  const siteIsSelected = (site) => {
    return selectedSites.has(site.uuid);
  };

  useEffect(() => {
    if (user) {
      setSelectedSites(new Set(user?.authorized_sites));
    }
  }, [user]);

  useEffect(() => {
    updateAllSelected(selectedSites, siteList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSites, siteList]);

  const getSiteControl = (siteName, siteUuid, selected) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={selected}
            onChange={(ev) => {
              handleSiteSelectionChange(siteUuid, ev);
              setDirty(true);
            }}
            size="large"
          />
        }
        label={<Typography variant="body2">{siteName}</Typography>}
      />
    );
  };

  const getSiteControlList = (siteList) => {
    if (siteListIsLoading) {
      return (
        <div className="chart-graph">
          <Loading />
        </div>
      );
    }
    if (!siteList) {
      return (
        <Box sx={styles.noSitesContainer}>
          <Typography variant="body-2">No sites</Typography>
        </Box>
      );
    }

    const filteredSites = siteList.sites.filter(
      (site) => site.state === "commissioned" || site.state === "Operating",
    );

    const orderedSites = filteredSites.sort((a, b) => a.site_name.localeCompare(b.site_name));

    if (!orderedSites.length) {
      return (
        <Box sx={styles.noSitesContainer}>
          <Typography variant="body-2">No sites</Typography>
        </Box>
      );
    }

    const rowCount = Math.ceil(orderedSites.length / 3);
    return (
      <Box
        sx={[styles.controlList, { gridTemplateRows: `repeat(${rowCount}, minmax(4rem, auto))` }]}
      >
        {orderedSites.map((site) => {
          return getSiteControl(site.site_name, site.uuid, siteIsSelected(site));
        })}
      </Box>
    );
  };

  const getSiteAuthorizationPanel = () => (
    <Box sx={[theme.classes.genericPanel, { marginTop: "2rem" }]}>
      <Typography variant="subtitle2" sx={styles.subtitle}>
        Site Access
      </Typography>
      {!isLoading && (
        <>
          <Grid container>
            <Grid item xs={8}>
              <Typography variant="caption" sx={styles.caption}>
                Select sites to grant access to this user. Newly added sites default to hidden.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Button onClick={handleToggleSelectAll} variant="outlined" disabled={!siteList}>
                Select / Deselect All
              </Button>
            </Grid>
          </Grid>
          <Box sx={styles.settingsPanel}>{getSiteControlList(siteList)}</Box>
        </>
      )}
      {isLoading ? <Loading /> : null}
    </Box>
  );

  return (
    <>
      {errorMessage && (
        <Alert severity="error" onClose={() => setErrorMessage("")}>
          {errorMessage}
        </Alert>
      )}
      <Box>
        <Box sx={user?.email && theme.classes.genericPanel}>
          {user?.email ? (
            <Typography variant="subtitle2" sx={styles.subtitle}>
              User Details
            </Typography>
          ) : (
            <Typography sx={{ mb: "1.5rem", fontSize: "34px", fontWeight: 850 }}>
              New User
            </Typography>
          )}
          <Box
            display="flex"
            flexDirection="column"
            sx={user?.email ? styles.settingsPanel : { ...styles.settingsNewPanel }}
          >
            <FormGroup fullWidth variant="outlined" margin="normal">
              <Box display="flex" mb="1.5rem" alignItems="flex-start">
                <Box sx={{ marginRight: "1.5rem" }}>
                  <TextField
                    id="name"
                    name="name"
                    label="User Name"
                    value={name}
                    size="small"
                    required
                    variant="outlined"
                    onChange={(ev) => {
                      setName(ev.target.value);
                      setDirty(true);
                    }}
                    sx={{ width: "24.5rem", fontSize: 16, fontWeight: 400 }}
                    InputProps={{
                      sx: { fontSize: 16, fontWeight: 400 },
                    }}
                  />
                </Box>

                <Box>
                  <TextField
                    id="email"
                    name="email"
                    label="Email Address"
                    size="small"
                    value={email}
                    required
                    disabled={user}
                    variant="outlined"
                    onChange={(ev) => {
                      setEmail(ev.target.value);
                      setDirty(true);
                    }}
                    sx={{ width: "24.5rem", fontSize: 16, fontWeight: 400 }}
                    InputProps={{
                      sx: { fontSize: 16, fontWeight: 400 },
                    }}
                  />
                  {!user ? (
                    <Typography variant="body2" sx={{ mt: "8px", color: "text.secondary" }}>
                      Confirm correct email. This cannot be changed.
                    </Typography>
                  ) : (
                    <Box sx={{ mt: "24px" }} />
                  )}
                </Box>
              </Box>
              <Box>
                <Box display="flex" flexDirection="row" mb="1.5rem" alignItems="center">
                  <FormControl sx={{ width: "24.5rem", marginRight: "1.5rem" }}>
                    <Autocomplete
                      id="organizations"
                      options={organizationsList?.customers || []}
                      size="small"
                      getOptionLabel={(option) => option.name}
                      value={
                        organizationsList?.customers.find((item) => item.uuid === organization) ||
                        null
                      }
                      onChange={(event, newValue) => handleOrganizationChange(newValue)}
                      renderInput={(params) => (
                        <TextField {...params} label="Organization" variant="outlined" required />
                      )}
                      key={(option) => option.uuid}
                    />
                  </FormControl>
                  <FormControl sx={{ width: "11.5rem", marginRight: "1.5rem" }}>
                    <InputLabel htmlFor="userRole" required>
                      Role
                    </InputLabel>
                    <Select
                      id="userRole"
                      name="userRole"
                      label="Role"
                      required
                      size="small"
                      value={role}
                      variant="outlined"
                      onChange={(ev) => {
                        setRole(ev.target.value);
                        setDirty(true);
                      }}
                      sx={{
                        "& .MuiSelect-select": {
                          fontSize: 16,
                          fontWeight: 400,
                        },
                      }}
                    >
                      {getRoles()}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </FormGroup>

            {isLoading ? <Loading /> : null}
            {error ? <DataComponentError /> : null}
          </Box>
        </Box>
        {user && getSiteAuthorizationPanel()}
        <Box
          pt="2em"
          display="flex"
          flexDirection="row"
          width="100%"
          justifyContent="space-between"
        >
          {user?.email ? (
            <Button
              sx={{
                width: "11.5rem",
              }}
              variant="outlined"
              onClick={handleDelete}
              color="error"
            >
              Delete User...
            </Button>
          ) : (
            <Box flexGrow={1} />
          )}
          <Box>
            {!user?.email && (
              <Button
                variant="outlined"
                sx={{
                  marginLeft: "1em",
                  width: "150px",
                  color: "rgba(0, 0, 0, 0.87)",
                  borderColor: "rgba(0, 0, 0, 0.87)",
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
            )}
            <LoadingButton
              loading={mutation?.isLoading}
              onClick={hasUpdates() ? handleSaveAndClose : () => {}}
              variant="contained"
              sx={{ marginLeft: "1em", width: "11.5rem" }}
              disabled={
                (!selectedSitesChanged() && !hasUpdates()) ||
                (!allFieldsFilled() && !mutation?.isSuccess)
              }
              color={mutation?.isSuccess && !hasUpdates() ? "success" : "primary"}
            >
              {mutation.isSuccess && !hasUpdates()
                ? "Saved"
                : user?.email
                  ? "Save Changes"
                  : "Create User"}
            </LoadingButton>
            <DeleteUserModal
              open={open}
              onClose={() => setOpen(false)}
              onConfirm={confirmDelete}
              userName={user?.name}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

UserSettings.propTypes = {
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
};

export default UserSettings;
