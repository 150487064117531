/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { Box, Typography, Button, FormControlLabel, Switch } from "@mui/material";
import {
  getSiteTimeSeriesQuery,
  getSiteAssembliesQuery,
  getAlarmHistoryQuery,
} from "../../../lib/Queries";
import {
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  ReferenceLine,
  ReferenceArea,
  ComposedChart,
} from "recharts";
import DropdownEnergy from "../Common/DropdownEnergy";
import LineGraphTooltip from "../Common/LineGraphTooltip";
import CustomLegend from "../Common/CustomLegend";
import Loading from "../../Shared/Loading";
import DataComponentError from "../../Shared/DataComponentError";
import { assemblyColor, assemblyFillColor } from "../Logic/formatUtils";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import DateOptionPicker from "../Common/DateOptionPicker";
import { decorateAlarms } from "../../../lib/Util";
import GraphUtil from "../../../lib/GraphUtil";
import AlarmTimeline from "../Common/AlarmTimeline";
import CustomTick from "../Common/CustomTick";
import CustomXAxis from "../Common/CustomXAxis";
import DownloadDialog from "../Common/DownloadDialog";
import AlarmsDownloadDialog from "../Common/AlarmsDownloadDialog";

const SitePowerGraph = ({ site }) => {
  const [manualRefresh, setManualRefresh] = useState(false);
  const [timeSeries, setTimeSeries] = useState([]);
  const [timezone] = useState(site.timezone);
  const [objKeys, setObjKeys] = useState([]);
  const [areaVisibility, setAreaVisibility] = useState({});
  const [animation, setAnimation] = useState(true);
  const [animationCount, setAnimationCount] = useState(0);
  const [bigBuckets, setBigBuckets] = useState([]);
  const [zoomParams, setZoomParams] = useState({ zoomed: false });
  const [preZoomDisplayRange, setPreZoomDisplayRange] = useState(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [useGraphInterpolation, setUseGraphInterpolation] = useState(true);
  const [displayRange, setDisplayRange] = useState(GraphUtil.getInitialGraphRange(timezone));
  const [powerDownloadOpen, setPowerDownloadOpen] = useState(false);
  const [alarmsDownloadOpen, setAlarmsDownloadOpen] = useState(false);
  const [graphPayload, setGraphPayload] = useState(null);

  const updateAnimationState = (animation) => {
    setAnimation(false);
  };

  /**
   * Handles the onAnimationEnd event of the Area components.
   * Increments the animation count and checks if all animations are completed.
   * If all animations are completed, it sets the animation state to false.
   *
   * @returns {void}
   */
  const handleAnimationEnd = () => {
    setAnimationCount((prevCount) => prevCount + 1);
    if (animationCount >= 0) {
      setAnimation(false);
    }
  };

  const {
    isLoading: isSiteTimeSeriesLoading,
    error,
    data: siteTimeSeriesData,
    refetch,
    isRefetchError,
  } = useQuery({
    ...getSiteTimeSeriesQuery(
      site.uuid,
      displayRange.start,
      displayRange.stop,
      displayRange.interval,
    ),
  });

  const {
    isLoading: isAlarmHistoryLoading,
    data: alarmHistoryData,
    refetch: refetchAlarms,
  } = useQuery({
    ...getAlarmHistoryQuery(site.uuid, displayRange.start, displayRange.stop),
  });

  const { isLoading: isAssemblyDataLoading, data: assemblyData } = useQuery({
    ...getSiteAssembliesQuery(site.uuid),
  });
  const isLoading = isSiteTimeSeriesLoading || isAlarmHistoryLoading || isAssemblyDataLoading;
  useEffect(() => {
    // decorate the alarms in the history with assemblies, if we have them.
    if (alarmHistoryData) {
      decorateAlarms(alarmHistoryData.historical_alerts, site.assemblies);
    }
  }, [alarmHistoryData]);

  useEffect(() => {
    if (Object.keys(areaVisibility).length === 0 && objKeys.length > 0) {
      // Step 1: Filter only keys that include a dash
      const dashedKeys = objKeys.filter((key) => key.includes("-"));

      // Step 2: Count occurrences of typePart in the dashed keys
      const typePartCount = dashedKeys.reduce((acc, key) => {
        const [, typePart] = key.split("-");
        acc[typePart] = (acc[typePart] || 0) + 1;
        return acc;
      }, {});

      // Step 3: Build the final list based on the criteria
      const finalKeys = objKeys.filter((key) => {
        if (key.includes("-")) {
          const [namePart, typePart] = key.split("-");

          if (namePart === typePart) {
            // Include if namePart and typePart are the same
            return true;
          } else {
            // Include typePart if it appears more than once, otherwise include namePart
            return typePartCount[typePart] > 1
              ? key === `${typePart}-${typePart}`
              : key === `${namePart}-${typePart}`;
          }
        }
        // Include non-dashed keys
        return true;
      });
      // Step 4: Process finalKeys, removing duplicates but keeping the
      // first valid match
      const keysWithDash = finalKeys.filter((key) => key.includes("-"));
      const keysWithoutDash = finalKeys.filter((key) => !key.includes("-"));
      // Extract the first parts from keys with a dash
      const firstPartsFromDash = keysWithDash.map((key) => key.split("-")[0]);
      // Extract the second parts from keys with a dash
      const secondPartsFromDash = keysWithDash.map((key) => key.split("-")[1]);
      // Create a unique list starting with first parts of dashed keys, followed
      // by those without dashes, excluding those in second parts
      const uniqueKeys = [
        ...new Set(firstPartsFromDash),
        ...keysWithoutDash.filter((key) => !secondPartsFromDash.includes(key)),
      ];
      const initialAreaVisibility = uniqueKeys.reduce((acc, key) => {
        return { ...acc, [key]: true };
      }, {});
      // Alarms should always be visible
      initialAreaVisibility.alarms = true;
      setAreaVisibility(initialAreaVisibility);
    }
  }, [objKeys]);

  useEffect(() => {
    refetch();
    refetchAlarms();
    setManualRefresh(true);
  }, [displayRange]);

  useEffect(() => {
    if (assemblyData) {
      const assembliesObj = assemblyData.flatMap((item) => {
        const { group_assemblies, assemblies } = item;
        // Combine scaleos_name and scaleos_type with a dash
        const assemblyKeys = assemblies.map((obj) => `${obj.scaleos_name}-${obj.scaleos_type}`);
        return [group_assemblies, ...assemblyKeys];
      });
      setObjKeys(Array.from(new Set(assembliesObj)));
    }
  }, [assemblyData]);

  useEffect(() => {
    if (siteTimeSeriesData?.time_series && siteTimeSeriesData?.time_series.length > 0) {
      let seriesData = JSON.parse(JSON.stringify(siteTimeSeriesData.time_series));
      const { timeSeries: seriesDataWithBigBuckets, newBigBuckets } =
        GraphUtil.calculateBigBuckets(seriesData);
      setBigBuckets(newBigBuckets);
      setTimeSeries(seriesDataWithBigBuckets);
      setManualRefresh(false);
      setInitialLoadComplete(true);
    }
    setInitialLoadComplete(true);
  }, [siteTimeSeriesData]);

  const styles = {
    downloadButtonBox: {
      display: "flex",
      flexDirection: "column",
      gap: ".6rem",
    },
  };

  const leftMargin = 20;
  const rightMargin = 20;
  const yAxisWidth = 40;

  const removeAssetNumber = (asset_string) => {
    // Split the string by the dash and return the first part (before the dash)
    return asset_string.includes("-") ? asset_string.split("-")[0] : asset_string;
  };

  const getPayload = () => {
    const payload = objKeys.map((key) => {
      // Split the key to get the part before and after the dash
      const [namePart, typePart] = key.includes("-") ? key.split("-") : [key, null];

      return {
        value: removeAssetNumber(namePart), // Use the part before the dash
        type: typePart,
        color: typePart ? assemblyColor[typePart] : assemblyColor[namePart], // Use the part after the dash for color
      };
    });

    // Filter out entries that do not have typePart
    const filteredPayload = payload.filter((entry) => entry.type !== null);
    return filteredPayload;
  };

  const cancelZoom = () => {
    setZoomParams({ zooming: false, zoomed: false });
    setDisplayRange(GraphUtil.updateDisplayRange(preZoomDisplayRange));
  };

  const getBucketAlarms = GraphUtil.generateGetBucketAlarms(bigBuckets, alarmHistoryData);

  const showAlarms = areaVisibility["alarms"];

  if (!initialLoadComplete)
    return (
      <div className="chart-graph">
        <Box display="flex" flexDirection="row" mt="20px" ml="20px" alignItems="baseline">
          <Box>
            <Typography variant="body2" color="secondary">
              Site Power
            </Typography>
          </Box>
        </Box>
        <Loading />
      </div>
    );

  if (error || isRefetchError)
    return (
      <div className="chart-graph">
        <Box display="flex" flexDirection="row" mt="20px" ml="20px" alignItems="baseline">
          <Box>
            <Typography variant="body2" color="secondary">
              Site Power
            </Typography>
          </Box>
        </Box>
        <DataComponentError />
      </div>
    );

  if (siteTimeSeriesData) {
    return (
      <div className="chart-graph" style={{ top: "40px" }}>
        <Box
          width="95%"
          display="flex"
          flexDirection="row"
          mt="20px"
          ml="20px"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="body2" color="secondary">
              Site Power
            </Typography>
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={useGraphInterpolation}
                  onChange={() => setUseGraphInterpolation(!useGraphInterpolation)}
                />
              }
              label={<Typography variant="overline2">Interpolation</Typography>}
            />
          </Box>
        </Box>
        <Box
          width="95%"
          ml="20px"
          pt="30px"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="row">
            <DateOptionPicker
              site={site}
              updateDisplayRange={(range) => {
                setDisplayRange(GraphUtil.updateDisplayRange(range));
                setZoomParams({ zooming: false, zoomed: false });
              }}
              range={displayRange.range}
            />
            <DropdownEnergy site={site} displayRange={displayRange} manualRefresh={manualRefresh} />
          </Box>
          <Box sx={styles.downloadButtonBox}>
            <Button
              variant="outlined"
              size="small"
              color="alt"
              onClick={() => setPowerDownloadOpen(true)}
            >
              Download Power Data
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="alt"
              onClick={() => setAlarmsDownloadOpen(true)}
            >
              Download Alarms Data
            </Button>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box flexGrow="1">
            <Box display="flex" flexDirection="column" width="100%">
              {isLoading || manualRefresh ? (
                <>
                  <Box display="flex" flexDirection="row" mt="20px" ml="20px" alignItems="baseline">
                    <Box>
                      <Typography variant="body2" color="secondary">
                        Site Power
                      </Typography>
                    </Box>
                  </Box>
                  <Loading />
                </>
              ) : (
                <>
                  <Box sx={{ width: "100%", height: "400px" }}>
                    <ResponsiveContainer width="99%" height="99%">
                      <ComposedChart
                        data={timeSeries}
                        margin={{
                          top: 0,
                          right: rightMargin,
                          left: leftMargin,
                          bottom: 0,
                        }}
                        onMouseDown={(ev) =>
                          GraphUtil.handleZoomStart(ev, zoomParams, setZoomParams)
                        }
                        onMouseUp={(ev) => {
                          const newRange = GraphUtil.handleZoomEnd(
                            ev,
                            zoomParams,
                            setZoomParams,
                            timezone,
                            displayRange,
                          );
                          setPreZoomDisplayRange(displayRange);
                          setDisplayRange(GraphUtil.updateDisplayRange(newRange));
                        }}
                        onMouseMove={(ev) => {
                          GraphUtil.handleZoomUpdate(ev, zoomParams, setZoomParams);
                          setGraphPayload(ev.activePayload);
                        }}
                        onMouseLeave={() => {
                          setGraphPayload(null);
                        }}
                        width={40}
                      >
                        <CustomXAxis hide={false} />
                        <YAxis
                          unit="kW"
                          tick={<CustomTick unit="kW" />}
                          axisLine={false}
                          style={{
                            fontFamily: "Inter, Arial, Helvetica, sans-serif",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "10px",
                            color: "#bebebe",
                          }}
                          width={yAxisWidth}
                        />
                        <Tooltip
                          wrapperStyle={{ zIndex: 10000 }}
                          content={
                            <LineGraphTooltip
                              areaVisibility={areaVisibility}
                              // timeSeries item implicitly passed in from ComposedChart
                              getBucketAlarms={getBucketAlarms}
                              timezone={timezone}
                              showAlarms={showAlarms}
                              graphPayload={graphPayload}
                            />
                          }
                          filterNull={false}
                        />
                        {objKeys.map((key, index) => {
                          const [namePart, typePart] = key.includes("-")
                            ? key.split("-")
                            : [key, key];
                          // Skip the entry if namePart and typePart are the same and key includes a dash
                          if (namePart === typePart && key.includes("-")) {
                            return null; // Skip this entry
                          }
                          const isVisible = areaVisibility[namePart];
                          return (
                            <Area
                              isAnimationActive={animation}
                              onAnimationEnd={handleAnimationEnd}
                              key={namePart}
                              type={useGraphInterpolation ? "monotone" : "linear"}
                              stackId={typePart === "Load" ? "2" : "1"}
                              dataKey={namePart}
                              stroke={assemblyColor[typePart]}
                              strokeWidth={2}
                              fillOpacity={typePart === "Load" ? 0 : 1}
                              fill={assemblyFillColor[typePart]}
                              {...(typePart === "Load"
                                ? { strokeDasharray: "1 1", strokeWidth: 1 }
                                : {})}
                              hide={!isVisible}
                              connectNulls={useGraphInterpolation ? true : false}
                              style={{ zIndex: 20 }}
                            />
                          );
                        })}
                        <CartesianGrid
                          strokeDasharray="4 4"
                          stroke="#808080"
                          strokeOpacity="10%"
                          vertical={false}
                        />
                        <ReferenceLine
                          y={0}
                          stroke="#325182"
                          strokeDasharray="3 3"
                          isFront={true}
                        />
                        {zoomParams?.zooming ? (
                          <ReferenceArea x1={zoomParams.startIndex} x2={zoomParams.stopIndex} />
                        ) : null}
                      </ComposedChart>
                    </ResponsiveContainer>
                  </Box>
                  <AlarmTimeline
                    timeSeries={timeSeries}
                    displayRange={displayRange}
                    bigBuckets={bigBuckets}
                    alarmHistoryData={alarmHistoryData}
                    yAxisMargin={40}
                    axisOnly={!showAlarms}
                    timezone={timezone}
                    getBucketAlarms={getBucketAlarms}
                    rightMargin={rightMargin}
                    leftMargin={leftMargin + yAxisWidth}
                  />
                </>
              )}
            </Box>
          </Box>
          <Box
            flexBasis="auto"
            pr="2rem"
            flexDirection="column"
            display="flex"
            justifyContent="space-around"
          >
            <CustomLegend
              payload={getPayload()}
              areaVisibility={areaVisibility}
              setAreaVisibility={setAreaVisibility}
              updateAnimationState={updateAnimationState}
            />
            {zoomParams.zoomed ? (
              <Button variant="outlined" size="small" color="alt" onClick={cancelZoom}>
                Cancel Zoom
              </Button>
            ) : null}
          </Box>
        </Box>
        <DownloadDialog
          open={powerDownloadOpen}
          start={displayRange.start}
          stop={displayRange.stop}
          defaultInterval={displayRange.interval}
          onClose={() => setPowerDownloadOpen(false)}
          siteUuid={site.uuid}
          siteName={site.site_name}
        />
        <AlarmsDownloadDialog
          open={alarmsDownloadOpen}
          start={displayRange.start}
          stop={displayRange.stop}
          onClose={() => setAlarmsDownloadOpen(false)}
          siteUuid={site.uuid}
          siteName={site.site_name}
          timezone={timezone}
        />
      </div>
    );
  }
};

SitePowerGraph.propTypes = {
  site: PropTypes.object.isRequired,
};

export default SitePowerGraph;
